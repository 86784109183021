<template>
    <section class="hero is-large">
        <div class="hero-body">
            <div class="is-flex is-flex-direction-row is-justify-content-center">
                <font-awesome-icon :icon="['fa', 'file-code']" size="2x" />
                <h1 class="title ml-4">Page Not Found</h1>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "NotFound",
    };
</script>

<style scoped>
</style>